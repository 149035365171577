<template>
    <section id="firstBanner" class="secondBanner bg-grey">
      <b-container class="topLogoContainer" fluid>
        <div class="videoBg" :style="{ minHeight: firstBannerTotalH + 'px' }">
          <client-only>
            <div
              class="bannerVideo embed-responsive embed-responsive-16by9"
              :style="{ minHeight: firstBannerTotalH + 'px' }"
            >
              <video
                autoplay
                muted
                loop
                class="embed-responsive-item"
                allowfullscreen
                alt="Ariel drone shot of city scape."
                playsinline
              >
                <source src="/img/southbay/VIDEO_LOOP_ok.mp4" type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
          </client-only>
        </div>
        <div
          class="upperLayout d-flex align-items-center justify-content-center"
          :style="{ minHeight: firstBannerH + 'px' }"
        >
        </div>
      </b-container>
    </section>
  </template>
  
  <script>
  import jq from "jquery";
  export default {
    data() {
      return {
        firstBannerH: null,
        firstBannerTotalH: null,
      };
    },
    mounted() {
      this.$nextTick(() => {
        this.getMidHeight();
        jq(window).on("resize", () => {
          this.getMidHeight();
          setTimeout(() => {
            this.getMidDomHeight();
          }, 500);
        });
        setTimeout(() => {
          this.getMidDomHeight();
        }, 100);
      });
    },
    methods: {
      getMidHeight() {
        this.firstBannerH = jq(window).height();
      },
      getMidDomHeight() {
        this.firstBannerTotalH = jq(".secondBanner").height();
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "./assets/styles/variables";
  .secondBanner {
    background-color: $m-cream;
    overflow: hidden;
    .topLogoContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      .videoBg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        video {
          object-fit: cover;
          background-color: $m-cream;
        }
      }
      .upperLayout {
        width: 100%;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .southbayLogo {
          width: 400px;
          // position:absolute;
          display: block;
          // left:50%;
          // top:25%;
          // margin-top:0;
          // transform: translate(-50%, -50%) scale(0.7);
          // @media screen and (max-width: 1024px) {
          //   transform: translate(-50%, -50%) scale(0.8);
          // }
          // @media screen and (max-width: 450px) {
          //   transform: translate(-50%, -50%) scale(1);
          // }
        }
        .footer-logo {
          // width: 300px;
          height: 15vh;
          position: absolute;
          bottom: 0;
          .footerImg {
            width: 100px;
          }
          .line {
            border-right: 1px solid white;
            // height: 7em;
          }
        }
      }
    }
  }
  </style>
  